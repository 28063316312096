import React, { useContext, useState } from "react"
import { Link } from 'gatsby'

import { ShopContext } from "../context/shop-context"
import { getPrice } from '../utils/getPrice'

const LineItem = ({ item }) => {
  const { removeProductFromCart, checkout, isLoading, setLoading, updateQuantity } = useContext(ShopContext)
  const [quantity, setQuantity] = useState(1);

  if (item.quantity !== quantity && quantity !== '' && !isLoading) {
    setQuantity(item.quantity);
  }

  const handleChange = event => {
    if (isLoading) {
      return;
    }

    const value = event.target.value;

    // Make sure the quantity is always at least 1.
    const safeValue = Math.max(Number(value), 0);

    // No need to update if the value hasn’t updated.
    if (value === quantity) {
      return;
    }

    // If the field is empty, update the state but don’t do anything else.
    if (value === '') {
      setQuantity(value);
      return;
    }

    // Otherwise, trigger the loading state and set the quantity in state.
    setLoading(true);
    setQuantity(safeValue);

    // If the quantity is set to 0, remove the item.
    if (safeValue === 0) {
      removeProductFromCart(event);
      return;
    }

    // If we get here, update the quantity.
    updateQuantity(item.id, safeValue);
  };

  const variantImage = item.variant.image ? (
    <img
      src={item.variant.image.src}
      alt={`${item.title} product shot`}
      className="h-16"
    />
  ) : null

  return (
    <div className="flex mb-4 justify-between">
      <Link to={`/product/${item.variant.product.handle}`} className="flex w-full sm:w-2/3">
        <div className="mr-4">{variantImage}</div>
        <div className="w-3/5 flex-grow mr-4">
          <p className="leading-none">{item.title}</p>
          <p className="text-sm md:text-base text-gray-500">
            {item.variant.title}, {getPrice(item.variant.price, checkout)}
          </p>
        </div>
      </Link>
      <p className="w-1/6 text-sm md:text-base leading-none sm:ml-4">
        <input
          aria-label="Quantity"
          id={`quantity-${item.id}`}
          type="number"
          name="quantity"
          inputMode="numeric"
          min="1"
          step="1"
          onChange={event => handleChange(event, item.id, quantity)}
          onBlur={() => setQuantity(item.quantity)}
          value={quantity}
          className="rounded-none focus:outline-none focus:shadow-outline transition duration-150 ease-in-out border border-gray-300 w-12 h-8 pl-2 mr-4"
        />
      </p>
      <div className="w-1/5 text-right">
        <button
          onClick={() => {
            removeProductFromCart(item.id)
          }}
          className="bg-black text-white px-2 py-1 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
        >
          Remove
        </button>
      </div>
    </div>
  )
}

export default LineItem
