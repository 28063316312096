import React, { useContext } from "react"
import { Link } from "gatsby"

import { ShopContext } from "../context/shop-context"
import SEO from "../components/seo"
import LineItem from "../components/line-item"
import ShopLayout from "../templates/shop-layout"
import { getPrice } from "../utils/getPrice"

const CartPage = () => {
  const { checkout } = useContext(ShopContext)
  const checkoutUrl = checkout?.webUrl?.replace(
    "yams-studio.myshopify.com",
    "checkout.yams.studio"
  )

  const lineItems = checkout?.lineItems.map(item => {
    return <LineItem key={item.id} item={item} />
  })

  return (
    <ShopLayout>
      <SEO title="Yams Studio: Cart" />
      <div className="grid grid-cols-1 px-5 my-12 max-w-lg mx-auto">
        <h2 className="text-lg sm:text-xl mb-6">Cart</h2>
        {checkout?.lineItems.length > 0 ? (
          <>
            {lineItems}
            <h3 className="leading-none mt-4">Subtotal</h3>
            <p className="mb-2">
              {getPrice(checkout?.subtotalPrice, checkout)}{" "}
              {checkout?.currencyCode}
            </p>
            <h3 className="leading-none">Taxes</h3>
            <p className="mb-2">Included</p>
            <h3 className="leading-none">Total</h3>
            <p>
              {getPrice(checkout?.totalPrice, checkout)}{" "}
              {checkout?.currencyCode}
            </p>
            <div className="my-8">
              <a
                href={checkoutUrl}
                className="text-center bg-black text-white px-4 py-2 focus:outline-none focus:shadow-outline transition duration-150 inline-block w-full sm:w-auto ease-in-out cursor-pointer select-none"
              >
                Check out
              </a>
            </div>
            <p>Shipping costs calculated at checkout.</p>
          </>
        ) : (
          <p>
            Your cart is empty.{" "}
            <Link
              to="/shop"
              className="text-blue-500 hover:text-blue-400 underline transition duration-150 ease-in-out"
            >
              Continue shopping
            </Link>
            .
          </p>
        )}
      </div>
    </ShopLayout>
  )
}

export default CartPage
